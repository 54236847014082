import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
	backgroundColor: theme.palette.background.paper,
  },
}));

const ListGroup = ({ items, textProperty, valueProperty, selectedItem, onItemSelect, filterName }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<List component="nav" aria-label="main mailbox folders" subheader={<ListSubheader component="div" id="nested-list-subheader">Filter by {filterName}</ListSubheader>}>
				{items.map(item => (
					<ListItem button selected={item === selectedItem} onClick={() => onItemSelect(item)} key={item[valueProperty]}>
						<ListItemIcon style={{minWidth:25}}>
							<ArrowRightIcon />
						</ListItemIcon>
						<ListItemText primary={item[textProperty]} />
					</ListItem>
				))}
			</List>
		</div>
	);
};

ListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default ListGroup;
