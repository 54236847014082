import React from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import auth from "../services/authService";

const user = auth.getCurrentUser();
let editLink = '/users/';
if (user) {
    editLink = `/users/${user._id}`;
}

const Profile = () => {
    return (
        <Grid container spacing={2} style={{marginTop: 20}}>
            <Grid item xs={12}>
                <Card style={{marginBottom: 20}}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <div className="title-container">
                                    <h1>My Profile</h1>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="align-right">
                                    <Button variant="contained" style={{marginTop:0}} endIcon={<EditIcon/>} component={Link} to={editLink}>
                                        Edit Profile
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <ul>
                            <li><strong>Name:</strong> {user.firstName} {user.lastName}</li>
                            <li><strong>Username:</strong> {user.username}</li>
                            <li><strong>Email:</strong> {user.email}</li>
                        </ul>
                                
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Profile;
