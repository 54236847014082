import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getUser, saveUser } from "../services/userService";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import History from "./common/history";
import { getHistory, saveHistory } from '../services/historyService';
import auth from "../services/authService";

class UserForm extends Form {
	state = {
		data: {
			firstName: "",
			lastName: "",
			username: "",
			email: "",
			password: "",
			passwordConfirmation: "",
		},
		errors: {},
		allValues: [],
		defaultValues: [],
		history: [],
	};

	schema = {
		_id: Joi.string(),
		firstName: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("First Name"),
		lastName: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("Last Name"),
		username: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("Username"),
		email: Joi.string()
			.required()
			.min(1)
			.max(250)
			.email()
			.label("Email"),
		password: Joi.string()
			.required()
			.min(5)
			.max(1024)
			.label("Password"),
		passwordConfirmation: Joi.any()
			.valid(Joi.ref('password'))
			.required()
			.label("Password Confirmation")
			.options({ 
				language: { 
					any: { allowOnly: 'must match password' } 
				} 
			})
	};

	async populateHistory() {
		try{
			const userId = this.props.match.params.id;
			if (userId === "new") return;

			const { data: history } = await getHistory(userId);
			const dataHistory = history.map((element) => {
				const objectArray = Object.values(element);
				return objectArray;
			});

			this.setState({ history: dataHistory });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async populateUser() {
		try {
			const userId = this.props.match.params.id;
			if (userId === "new") return;
			
			const { data: user } = await getUser(userId);
			this.setState({ data: this.mapToViewModel(user) });
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 404)
				this.props.history.replace("/not-found");
		}
	}

	async componentDidMount() {
		await this.populateHistory();
		await this.populateUser();
	}

	mapToViewModel(user) {
		return {
			_id: user._id,
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			email: user.email,
		};
	}

	doSubmit = async () => {
		const { data } = { ...this.state };
		delete data.passwordConfirmation;
		const { data: savedUser } = await saveUser(data);
		const contentId = this.props.match.params.id;
		const user = auth.getCurrentUser();
		
		delete data.password;
		const history = {
			userId: user._id,
			contentId,
			action: 'EDIT',
			payload: JSON.stringify(data),
			contentType: 'User',
		}

		if (contentId === "new") {
			history.contentId = savedUser._id;
			history.action = 'NEW';
		}

		await saveHistory(history);

		this.props.history.push("/users");
	};

  	render() {

		return (
			<Grid container spacing={2} style={{marginTop: 20}}>
				<Grid item xs={12}>
					<Card style={{marginBottom: 20}}>
      					<CardContent>
							<div className="title-container">
								<h1>User Profile</h1>
							</div>
							<form onSubmit={this.handleSubmit}>
								{this.renderInput("firstName", "First Name")}
								{this.renderInput("lastName", "Last Name")}
								{this.renderInput("username", "Username")}
								{this.renderInput("email", "Email")}
								
								{ this.props.match.params.id === "new" && 
									(this.renderInput("password", "Password", false, "password"))	
								}

								{ this.props.match.params.id === "new" && 
									(this.renderInput("passwordConfirmation", "Password Confirmation", false, "password"))	
								}

								{this.renderButton("Save")}
								{this.renderButton("Cancel", "link", "/users")}
							</form>
						</CardContent>
					</Card>

					{this.props.match.params.id !== "new" && this.state.history.length !== 0 &&
						<div className="history-content">
							<History data={this.state.history}/>
						</div>
	  				}
				</Grid>
			</Grid>
		);
  	}
}

export default UserForm;
