import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

// severity: error, warning, info, success

export default function TransitionAlerts({ open, onClick, message, severity = 'info', ...rest }) {
	const classes = useStyles();
  	return (
		<div className={classes.root}>
			<Collapse in={open} style={{marginBottom:20}}>
				<Alert
					severity={severity}
					action={
						<IconButton aria-label="close" color="inherit" size="small" onClick={onClick}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					{message}
				</Alert>
			</Collapse>
		</div>
	);
}
