import React, { Component } from "react";
import auth from "../services/authService";
import { Link } from "react-router-dom";
import Table from "./common/table";
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


class EventTable extends Component {
	columns = [
		{ path: "league", label: "League" },
		{ path: "site", label: "Site" },
	];

	viewAdminColumn = {
		path: "name",
		label: "Event Name",
		content: event => <Link to={`/events/${event._id}`}>{event.name}</Link>
	};

	viewColumn = {
		path: "name",
		label: "Event Name"
	};

	editColumn = {
		key: "edit",
		content: event => (
			<Button className="btn-sm" component={Link} to={`/events/${event._id}`}>
				<EditIcon style={{fontSize: 20}}/>
			</Button>
		),
		class: "actionColumn"
	};

	deleteColumn = {
		key: "delete",
		content: event => (
			<React.Fragment>
				<Button className="btn-sm" aria-label="delete" onClick={() => this.props.onClick(event)}>
					<DeleteIcon className="btn-danger" style={{fontSize: 20}}/>
				</Button>
			</React.Fragment>
		),
		class: "actionColumn"
	};

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) {
		this.columns.push(this.editColumn, this.deleteColumn);
		this.columns.unshift(this.viewAdminColumn);
	} else {
		this.columns.unshift(this.viewColumn);
	}
  }

  render() {
	const { events, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={events}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default EventTable;
