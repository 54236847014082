import http from "./httpService";
import { apiUrl, streamingUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const streamingApi = (window.REACT_APP_STREAMING_URL) ? window.REACT_APP_STREAMING_URL : streamingUrl;

export function getAlerts() {
	const apiEndpoint = `${api}/alerts/`;
	return http.get(apiEndpoint);
}

export function restartStreaming() {
	const apiEndpoint = `${streamingApi}/restart/`;
	return http.post(apiEndpoint);
}
