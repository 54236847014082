import http from "./httpService";
import { apiUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const apiEndpoint = `${api}/history`;

function historyUrl(id) {
  	return `${apiEndpoint}/${id}`;
}

export function getAllHistory() {
	return http.get(apiEndpoint);
}

export function getHistory(contentId) {
  	return http.get(historyUrl(contentId));
}

export function saveHistory(history) {
	return http.post(apiEndpoint, history);
}
