import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const apiEndpoint = `${api}/auth`;
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(user, password) {
	let userCreds = {
		username: user,
		password
	};
	if	(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user)) {
		userCreds = {
			email: user,
			password
		};
	}
	const { data: jwt } = await http.post(apiEndpoint, userCreds);
	localStorage.setItem(tokenKey, jwt);
}

export function loginWithJwt(jwt) {
	localStorage.setItem(tokenKey, jwt);
}

export function logout() {
	localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
	try {
		const jwt = localStorage.getItem(tokenKey);
		return jwtDecode(jwt);
	} catch (ex) {
		return null;
	}
}

export function getJwt() {
	return localStorage.getItem(tokenKey);
}

export default {
	login,
	loginWithJwt,
	logout,
	getCurrentUser,
	getJwt
};
