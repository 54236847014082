import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from "../services/authService";
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  doSubmit = async () => {
    try {
      	const { data } = this.state;
      	await auth.login(data.username, data.password);

      	const { state } = this.props.location;
      	window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      	if (ex.response && ex.response.status === 400) {
        	const errors = { ...this.state.errors };
			errors.username = ex.response.data;
			this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
		<React.Fragment>
			<Container maxWidth="sm">
				<Card style={{ marginTop: 20 }}>
					<CardContent>
						<h1>Login</h1>
						<form onSubmit={this.handleSubmit}>
							{this.renderInput("username", "Username")}
							{this.renderInput("password", "Password", false, "password")}
							{this.renderButton("Login")}
						</form>	
					</CardContent>
				</Card>
			</Container>
		</React.Fragment>
    );
  }
}

export default LoginForm;
