import React from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const Input = ({ name, label, error, ...rest }) => {
	// fullWidth
	// placeholder="Placeholder"
	// style={{ margin: 8 }}
    return (
        <div className="form-group">
            <FormControl variant="outlined" fullWidth>
				<InputLabel htmlFor={name}>{label}</InputLabel>
				<OutlinedInput {...rest} id={name} name={name} label={label} fullWidth style={{ marginBottom: 20}}/>
				{error && <div className="input-error">{error}</div>}
			</FormControl>
        </div>
    );
};

export default Input;
