import http from "./httpService";
import { apiUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const apiEndpoint = `${api}/handles`;

function handleUrl(id) {
	return `${apiEndpoint}/${id}`;
}

export function getHandles() {
	return http.get(apiEndpoint);
}

export function getHandle(handleId) {
	return http.get(handleUrl(handleId));
}

export function searchHandle(filter) {
	return http.get(handleUrl(`search?search=${filter}`));
}

export function saveHandle(handle) {
	if (handle._id) {
		const body = { ...handle };
		delete body._id;
		console.log('Saving...');
		return http.put(handleUrl(handle.handleId), body);
	}

  	return http.post(apiEndpoint, handle);
}

export function deleteHandle(handleId) {
  	return http.delete(handleUrl(handleId));
}
