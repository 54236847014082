import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Lists from "./components/lists";
import ListForm from "./components/listForm";
import Events from "./components/events";
import EventForm from "./components/eventForm";
import Handles from "./components/handles";
import HandleForm from "./components/handleForm";
import History from "./components/history";
import HistoryForm from "./components/historyForm";
import Users from "./components/users";
import UserForm from "./components/userForm";
import NotFound from "./components/notFound";
import NavBar from "./components/mainMenu";
import LoginForm from "./components/loginForm";
import RegisterForm from "./components/registerForm";
import Logout from "./components/logout";
import Profile from "./components/profile";
import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  	state = {};

	componentDidMount() {
		const user = auth.getCurrentUser();
		this.setState({ user });
	}

  	render() {
		const { user } = this.state;

		return (
			<React.Fragment>
				<ToastContainer />
				<NavBar user={user} />
				<main className="container">
					<Switch>
						{/* This endpoint will just return you to a dummy HTML with a simple heading tag */}
						<Route path="/api/status">
							<h1>Hey There!!! The App is Healthy</h1>
						</Route>

						<Route path="/register" component={RegisterForm} />
						<Route path="/login" component={LoginForm} />
						<Route path="/logout" component={Logout} />
						<ProtectedRoute path="/lists/:id" validateAdmin="true" component={ListForm} />
						<ProtectedRoute path="/lists" component={Lists} />
						<ProtectedRoute path="/events/:id" validateAdmin="true" component={EventForm} />
						<ProtectedRoute path="/events" component={Events} />
						<ProtectedRoute path="/handles/:id" validateAdmin="true" component={HandleForm} />
						<ProtectedRoute path="/handles" component={Handles} />
						<ProtectedRoute path="/history/:id" component={HistoryForm} />
						<ProtectedRoute path="/history" component={History} />
						<ProtectedRoute path="/users/:id" validateAdmin="true" component={UserForm} />
						<ProtectedRoute path="/users" component={Users} />
						<ProtectedRoute path="/profile" component={Profile} />
						<Route path="/not-found" component={NotFound} />
						<Redirect from="/" exact to="/lists" />
						<Redirect to="/not-found" />
					</Switch>
				</main>
			</React.Fragment>
		);
  	}
}

export default App;
