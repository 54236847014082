import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import AsyncSelect from "./asyncSelect";
import AutocompleteInput from "./autocompleteInput";
import Button from '@material-ui/core/Button';

class Form extends Component {
	state = {
		data: {},
		errors: {},
		value: null
	};

	validate = () => {
		const options = { abortEarly: false };
		const { error } = Joi.validate(this.state.data, this.schema, options);
		if (!error) return null;

		const errors = {};
		for (let item of error.details) errors[item.path[0]] = item.message;
		return errors;
	};

	validateProperty = ({ name, value }) => {
		const obj = { [name]: value };
		const schema = { [name]: this.schema[name] };
		const { error } = Joi.validate(obj, schema);
		return error ? error.details[0].message : null;
	};

	handleSubmit = e => {
		e.preventDefault();

		console.log('Submitting...');

		const errors = this.validate();
		console.log('Errors',errors);
		this.setState({ errors: errors || {} });
		if (errors) return;

		this.doSubmit();
	};

	handleChange = ({ currentTarget: input }) => {
		const errors = { ...this.state.errors };
		let errorMessage = this.validateProperty(input);
		
		if (input.id === "passwordConfirmation") {
			if (this.state.data.password === input.value) errorMessage = null;
		}

		if (errorMessage) errors[input.name] = errorMessage;
		else delete errors[input.name];

		this.setState({ errors });

		if (input.id === "league") {
			this.handleGetTeams(input.value, true);
		}

		if (input.id === "team") {
			this.handleTeamChange(input.value);
		}

		const data = { ...this.state.data };
		const shouldBlockNavigation = true;
		data[input.name] = input.value;

		this.setState({ data, shouldBlockNavigation });
	};

	handleAutocompleteChange = (event, value, name) => {
		const data = { ...this.state.data };

		if (value) {
			const selectedValues = value.map((item) =>{
				if (item.handleId) return item.handleId;
				return item;
			});

			data[name] = selectedValues;
			const shouldBlockNavigation = true;
			this.setState({ data, defaultValues: value, shouldBlockNavigation });
		}
	};

	renderButton(label, type = "submit", link = '#') {

		if(type === 'link') {
			return (
				<Button type="submit" variant="contained" color="default" style={{margin: 15}} component={Link} to={link}>
					{label}
				</Button>
			);	
		}

		return (
			<Button type={type} variant="contained" color="primary">
				{label}
			</Button>
		);
	}

	renderSelect(name, label, options) {
		const { data, errors } = this.state;
		return (
			<Select
				name={name}
				value={data[name]}
				label={label}
				options={options}
				onChange={this.handleChange}
				error={errors[name]}
			/>
		);
	}

	renderAsyncSelect(name, label, selectedValue, url) {
		const { data, errors } = this.state;

		return (
		<AsyncSelect
			name={name}
			value={data[name]}
			label={label}
			onChange={this.handleChange}
			url={url}
			error={errors[name]}
		/>
		);
	}

	renderInput(name, label, disabled = false, type = "text", className = "default-input") {
		const { data, errors } = this.state;

		return (
		<Input
			type={type}
			name={name}
			value={data[name]}
			label={label}
			onChange={this.handleChange}
			error={errors[name]}
			disabled={disabled}
			className={className}
		/>
		);
	}

	renderAutocompleteInput(name, label) {
		const { errors, allValues, defaultValues } = this.state;

		return (
			<AutocompleteInput
				name={name}
				options={allValues}
				// defaultValue={defaultValues}
				value={defaultValues}
				label={label}
				onChange={(event, value) => this.handleAutocompleteChange(event, value, name)}
				error={errors[name]}
			/>
		);
	}
}

export default Form;
