import React from 'react';
import { NavLink } from "react-router-dom";
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TwitterIcon from '@material-ui/icons/Twitter';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import { CircularProgress } from '@material-ui/core';
import { restartStreaming, getAlerts } from "../services/streamingService";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HistoryIcon from '@material-ui/icons/History';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexGrow: 1,
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
		}),
		marginRight: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.easeOut,
		duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));


const NavBar = ({ user }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorAlert, setAnchorAlert] = React.useState(null);
	const openBubble = Boolean(anchorEl);
	const openBubbleAlert = Boolean(anchorAlert);
	const [loadingBtn, setLoadingBtn] = React.useState(false);

	const [alert, setAlert] = React.useState(0);
	const [alertMessage, setAlertMessage] = React.useState('No Alerts.');

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuAlert = (event) => {
		setAnchorAlert(event.currentTarget);
	};
	
	const handleCloseAlert = () => {
		setAnchorAlert(null);
	};

	const handleRestart = async () => {
		setLoadingBtn(true);
		console.log('Restarting...');
		const { data: response } = await restartStreaming();
		console.log(response);
		setTimeout(() => setLoadingBtn(false), 1000); //1 seconds
		console.log('Restarted');
		handleAlerts();
	};

	const handleAlerts = async () => {
		const { data: response } = await getAlerts();
		if(response) {
			const streamingAlert = response.find((alert) => {
				return alert.name === 'twitter-streaming';
			});
			if(streamingAlert && streamingAlert.error){
				const message = streamingAlert.message;
				setAlert(1);
				setAlertMessage(message);
			} else {
				setAlert(0);
				setAlertMessage('No Alerts.');
			}
		}

	};

	handleAlerts();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
				})}
			>
				<div className="container">
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton,open && classes.hide)}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap className={classes.title}>
							Twitter Management Tool
						</Typography>

						{user && (
							<React.Fragment>
								<Button variant="contained" color="secondary" className="btn-restart" onClick={handleRestart} disabled={loadingBtn}>
									{loadingBtn && 
										<React.Fragment>
											<CircularProgress size={16} /> &nbsp; Restarting...
										</React.Fragment>
									}
									{!loadingBtn && 
										<React.Fragment>
											<CachedIcon /> &nbsp; Restart Streaming
										</React.Fragment>
									}
								</Button>

								<IconButton aria-label="show 17 new notifications" color="inherit" onClick={handleMenuAlert}>
									<Badge badgeContent={alert} color="secondary">
										<NotificationsIcon />
									</Badge>
								</IconButton>
							</React.Fragment>
						)}

						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>

						<Menu
							id="menu-notification"
							anchorEl={anchorAlert}
							keepMounted
							transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
							}}
							open={openBubbleAlert}
							onClose={handleCloseAlert}
							className="menu-popover"
						>
							{user && (
								<div>
									<MenuItem onClick={handleCloseAlert}>
										{alertMessage}
									</MenuItem>
								</div>
							)}
						</Menu>

						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
							}}
							keepMounted
							transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
							}}
							open={openBubble}
							onClose={handleClose}
							className="menu-popover"
						>
							{!user && (
								<div>
									<MenuItem onClick={handleClose}>
										<NavLink className="nav-item nav-link" to="/login">Login</NavLink>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<NavLink className="nav-item nav-link" to="/register">Register</NavLink>
									</MenuItem>
								</div>
							)}
							{user && (
								<div>
									<MenuItem onClick={handleClose}>
										<NavLink className="nav-item nav-link" to="/profile">Profile</NavLink>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<NavLink className="nav-item nav-link" to="/logout">Logout</NavLink>
									</MenuItem>
								</div>
							)}
						</Menu>
					</Toolbar>
				</div>
			</AppBar>
			
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
				paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</div>
				
				<Divider />
				
				<List className="main-menu">
					{user && (
						<React.Fragment>
							<NavLink className="nav-item nav-link" to="/lists" onClick={handleDrawerClose}>
								<ListItem button key="Lists">
									<ListItemIcon>{<TwitterIcon />}</ListItemIcon>
									<ListItemText primary="Twitter Lists" />
								</ListItem>
							</NavLink>
							<NavLink className="nav-item nav-link" to="/handles" onClick={handleDrawerClose}>
								<ListItem button key="Handles">
									<ListItemIcon>{<AlternateEmailIcon />}</ListItemIcon>
									<ListItemText primary="Twitter Handles" />
								</ListItem>
							</NavLink>
							<NavLink className="nav-item nav-link" to="/events" onClick={handleDrawerClose}>
								<ListItem button key="Events">
									<ListItemIcon>{<EventNoteIcon />}</ListItemIcon>
									<ListItemText primary="Twitter Events" />
								</ListItem>
							</NavLink>
							<NavLink className="nav-item nav-link" to="/users" onClick={handleDrawerClose}>
								<ListItem button key="Users">
									<ListItemIcon>{<PeopleAltIcon />}</ListItemIcon>
									<ListItemText primary="Users" />
								</ListItem>
							</NavLink>
							<NavLink className="nav-item nav-link" to="/history" onClick={handleDrawerClose}>
								<ListItem button key="ActivityLog">
									<ListItemIcon>{<HistoryIcon />}</ListItemIcon>
									<ListItemText primary="Activity Log" />
								</ListItem>
							</NavLink>
						</React.Fragment>
					)}
				</List>
				
			</Drawer>
			</div>
	);
  };

export default NavBar;
