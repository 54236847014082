import React, { Component } from "react";
import auth from "../services/authService";
import { Link } from "react-router-dom";
import Table from "./common/table";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';


class UserTable extends Component {
	columns = [
		{
		path: "username",
		label: "Username",
		content: user => <Link to={`/users/${user._id}`}>{user.username}</Link>
		},
		{ path: "firstName", label: "First Name" },
		{ path: "lastName", label: "Last Name" },
		{ path: "email", label: "Email" },
	];

	editColumn = {
		key: "edit",
		content: user => (
			<Button className="btn-sm" component={Link} to={`/users/${user._id}`}>
				<EditIcon style={{fontSize: 20}}/>
			</Button>
		),
		class: "actionColumn"
	};

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) {
		this.columns.push(this.editColumn);	
	}
  }

  render() {
    const { users, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={users}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default UserTable;
