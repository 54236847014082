import React from "react";
import { Link } from "react-router-dom";
import { Prompt } from 'react-router'
import Joi from "joi-browser";
import Form from "./common/form";
import { getEvent, saveEvent, deleteEvent } from "../services/eventService";
import { getHandles, getHandle } from "../services/handleService";
import { apiUrl } from "../config.json";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import History from "./common/history";
import { getHistory, saveHistory } from '../services/historyService';
import { getUser } from '../services/userService';
import auth from "../services/authService";
import TransitionAlerts from "./common/alerts";
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

class EventForm extends Form {
	state = {
		data: {
			name: "",
			league: "MLB",
			site: "www.cbssports.com",
			description: "",
			handles: {},
		},
		errors: {},
		allValues: [],
		defaultValues: [],
		history: [],
		siteOptions: [
			{
				_id: 'www.cbssports.com',
				name: 'www.cbssports.com'
			},
			{
				_id: 'www.sportsline.com',
				name: 'www.sportsline.com'
			}
		],
		leagueOptions: [
			{ _id: 'MLB', name: 'MLB' },
			{ _id: 'NBA', name: 'NBA' },
			{ _id: 'NFL', name: 'NFL' },
			{ _id: 'NHL', name: 'NHL' },
			{ _id: 'NCAAF', name: 'NCAAF' },
			{ _id: 'NCF1AA', name: 'NCF1AA' },
			{ _id: 'CFB', name: 'CFB' },
			{ _id: 'NCAAB', name: 'NCAAB' },
			{ _id: 'EPL', name: 'EPL' },
			{ _id: 'MLS', name: 'MLS' },
			{ _id: 'LIGA', name: 'LIGA' },
			{ _id: 'SCOT', name: 'SCOT' },
			{ _id: 'BUND', name: 'BUND' },
			{ _id: 'FRAN', name: 'FRAN' },
			{ _id: 'SERI', name: 'SERI' },
			{ _id: 'CHLG', name: 'CHLG' },
			{ _id: 'UEFA', name: 'UEFA' },
			{ _id: 'HOLL', name: 'HOLL' },
			{ _id: 'FMF', name: 'FMF' },
			{ _id: 'BRAZ', name: 'BRAZ' },
			{ _id: 'ARGE', name: 'ARGE' },
			{ _id: 'WCS', name: 'WCS' },
			{ _id: 'NCB2', name: 'NCB2' },
			{ _id: 'NCB3', name: 'NCB3' },
			{ _id: 'NAIAB', name: 'NAIAB' },
			{ _id: 'NCF2', name: 'NCF2' },
			{ _id: 'NCF3', name: 'NCF3' },
			{ _id: 'NAIA', name: 'NAIA' },
		],
		openAlert: false,
		selectedEvent: null,
		alertMessage: null,
		alertSeverity: 'info', // error, warning, info, success
		shouldBlockNavigation: false,
	};

	schema = {
		_id: Joi.string(),
		name: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("Name"),
		league: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("League"),
		site: Joi.string()
			.min(1)
			.max(250)
			.label("Site"),
		description: Joi.string()
			.max(500)
			.allow('')
			.label("Description"),
		handles: Joi.array()
			.label("Handles"),
	};

	async populateHandles() {
		try{
			const { data: allValues } = await getHandles();
			this.setState({ allValues });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async populateTeams() {
		// Call Team's logic
	}

	async handleGetTeams(league, teamChange = false){
		// Handles the Team selection
	};

	async populateHistory() {
		try{
			const eventId = this.props.match.params.id;
			if (eventId === "new") return;

			const { data: history } = await getHistory(eventId);
			const dataHistory = await Promise.all(history.map(async (element) => {
				const objectArray = Object.values(element);
				
				try {
					const { data: userId } = await getUser(objectArray[1]);
					if (userId) {
						objectArray[1] = userId.email;
					}
				} catch (error) {
					console.log(error);
				}

				return objectArray;
			}));

			this.setState({ history: dataHistory });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async populateEvent() {
		try {
			const eventId = this.props.match.params.id;
			if (eventId === "new") return;
			
			const { data: event } = await getEvent(eventId);
			this.setState({ data: this.mapToViewModel(event) });
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 404)
				this.props.history.replace("/not-found");
		}
	}

	async populateDefaultHandles() {
		try {
			const eventId = this.props.match.params.id;
			if (eventId === "new") return;

			const { data: event } = await getEvent(eventId);
			
			if (event.handles) {
				const eventHandles = event.handles;
				const handlesInfo = await Promise.all(eventHandles.map( async (handle) =>{
					const { data: handleInfo } = await getHandle(handle);
					return handleInfo[0];
				}));
				this.setState({ defaultValues: handlesInfo });
			}

		} catch (ex) {
			console.log('Handles not found: ', ex);
		}
	}

	async componentDidMount() {
		await this.populateHandles();
		await this.populateHistory();
		await this.populateEvent();
		await this.populateDefaultHandles();
	}

	handleAlertClose = () => {
		this.setState({ openAlert: false });
	};

	mapToViewModel(event) {
		
		let description = "";
		if (event.description) description = event.description;
		
		return {
			_id: event._id,
			name: event.name,
			league: event.league,
			site: event.site,
			description,
			handles: event.handles,
		};
	}

	doSubmit = async () => {
		const { data } = this.state;
		let alertMessage = "The Event was added.";
		let alertSeverity = 'success';

		try {
			const { data: savedEvent } = await saveEvent(this.state.data);

			const contentId = this.props.match.params.id;

			const user = auth.getCurrentUser();
			
			const history = {
				userId: user._id,
				contentId,
				action: 'EDIT',
				payload: JSON.stringify(data),
				contentType: 'Event',
			}

			if (contentId === "new") {
				history.contentId = savedEvent._id;
				history.action = 'NEW';
			}

			await saveHistory(history);

			alertMessage = "Event saved!";
			alertSeverity = 'success';
			this.setState({ openAlert: true, alertMessage, alertSeverity, shouldBlockNavigation: false });

			// this.props.history.push("/events");
		} catch (error) {
			alertMessage = "A Event with same name already exists, please use a different name.";
			alertSeverity = 'error';
			this.setState({ openAlert: true, alertMessage, alertSeverity, shouldBlockNavigation: true });
		}
	};

  	render() {
		const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
		const eventName = this.state.data.name.replace(/ /g,"%20");
		const eventApi = `${api}/feed/?events=${eventName}&limit=50`;

		return (
			<Grid container spacing={2} style={{marginTop: 20}}>
				<Grid item xs={12}>
					<Card style={{marginBottom: 20}}>
						<CardContent>
							<Grid container>
								<Grid item xs={6}>
									<div className="title-container">
										<h1>Event Form</h1>
									</div>
								</Grid>
								<Grid item xs={6} style={{textAlign: "right"}}>
									<Chip
										avatar={<Avatar className="chip-name">API</Avatar>}
										label={eventApi}
										variant="outlined"
									/>
								</Grid>
							</Grid>

							
							<Prompt
								when={this.state.shouldBlockNavigation}
								message="You have unsaved changes, are you sure you want to leave?"
							/>

							<TransitionAlerts open={this.state.openAlert} onClick={this.handleAlertClose} message={this.state.alertMessage} severity={this.state.alertSeverity}/>
							
							<Button variant="contained" style={{marginBottom:20}} startIcon={<KeyboardBackspaceIcon/>} component={Link} to="/events">
								Back to Events
							</Button>

							<form onSubmit={this.handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										{this.renderInput("name", "Name")}
									</Grid>
									<Grid item xs={6}>
										{this.renderSelect("site", "Site", this.state.siteOptions)}
									</Grid>
								</Grid>
								
								<Grid container spacing={2}>
									<Grid item xs={6}>
										{this.renderSelect("league", "League", this.state.leagueOptions)}
									</Grid>
									<Grid item xs={6}>
										{this.renderInput("description", "Description")}
									</Grid>
								</Grid>
								
								<Box className="no-margin-container handle-box">
									{this.renderAutocompleteInput("handles", "Handles")}
								</Box>

								{this.renderButton("Save")}
								{this.renderButton("Cancel", "link", "/events")}
								
								<Button variant="contained" style={{marginTop:0}} startIcon={<KeyboardBackspaceIcon/>} component={Link} to="/events">
									Back to Events
								</Button>
							</form>
						</CardContent>
					</Card>

					{this.props.match.params.id !== "new" && this.state.history.length !== 0 &&
						<div className="history-content">
							<History data={this.state.history}/>
						</div>
					}
				</Grid>
			</Grid>
		);
  	}
}

export default EventForm;
