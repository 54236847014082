import React, { Component } from "react";
import HistoryTable from "./historyTable";
import ListGroup from "./common/listGroup";
import { getAllHistory } from "../services/historyService";
import { getUser } from '../services/userService';
import { paginate } from "../utils/paginate";
import _ from "lodash";
import SearchBox from "./searchBox";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loading from "./common/loading";
import TransitionAlerts from "./common/alerts";
import TablePagination from '@material-ui/core/TablePagination';

class History extends Component {
  state = {
	types: [], // sites
	history: [], //lists
    currentPage: 0,
    pageSize: 10,
    searchQuery: "",
	selectedType: null, //selectedSite
	sortColumn: { path: "name", order: "asc" },
	open: false,
	openAlert: false,
	selectedHistory: null, //selectedList
	alertMessage: null,
	alertSeverity: 'info', // error, warning, info, success
	loading: true
  };

  async componentDidMount() {
	const { data: history } = await getAllHistory();

	if(history) {
		const dataHistory = await Promise.all(history.map(async (element) => {
			try {
				const { data: user } = await getUser(element.userId);
				if (user) {
					element.userId = user.email;
				}
			} catch (error) {
				console.log(error);
			}
			return element;
		}));
	}
	
	const typeList = [{ _id: "", contentType: "All Types" }, ...history];

	let availableTypes = [];
	let mergedTypeList = [];
	
	typeList.forEach((item) => {
		if(availableTypes.indexOf(item.contentType) === -1 && item.contentType !== undefined) {
			availableTypes.push(item.contentType);
			mergedTypeList.push({_id: item._id, name: item.contentType});
		}
	});

	this.setState({ history, selectedHistory: history[0], types: mergedTypeList, loading: false });
  }

  handleTypeSelect = type => {
	if (type._id) {
		this.setState({ selectedType: type, searchQuery: "", currentPage: 0 });
	} else {
		this.setState({ selectedType: null, searchQuery: "", currentPage: 0 });
	}
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, selectedType: null, currentPage: 0 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  handleChangeRowsPerPage = (event) => {
	const pageSize = parseInt(event.target.value);
	this.setState({ pageSize: pageSize, selectedType: null, currentPage: 0 });
  };

  handleClickOpen = history => {
	this.setState({ open: true, selectedHistory: history });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAlertOpen = list => {
	this.setState({ openAlert: true });
  };

  handleAlertClose = () => {
    this.setState({ openAlert: false });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
	  selectedType,
      searchQuery,
      history: allHistory
    } = this.state;

    let filtered = allHistory;
    if (searchQuery) {
		filtered = allHistory.filter((item) =>
			item.contentId.startsWith(searchQuery.toLowerCase())
		);
	} else if (selectedType) {
		filtered = allHistory.filter((item) => item.contentType === selectedType.name);
	}

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const history = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: history };
  };

  render() {
    const { length: count } = this.state.history;
	const { pageSize, currentPage, sortColumn, searchQuery, loading } = this.state;

	if (loading) {
		return (
			<Loading></Loading>
		);
	} else if (count === 0) {
		return (
			<React.Fragment>
				<p>There are no History in the database.</p>
			</React.Fragment>
		);
	}

	const { totalCount, data: history } = this.getPagedData();
	return (
		<React.Fragment>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<h1>Activity Log</h1>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
        		<Grid item md={3} xs={12}>
					<ListGroup
						items={this.state.types}
						selectedItem={this.state.selectedType}
						onItemSelect={this.handleTypeSelect}
						filterName="Type"
					/>
            	</Grid>
				<Grid item md={9} xs={12}>
					<Card style={{marginBottom: 20}}>
      					<CardContent>
						  	
							<TransitionAlerts open={this.state.openAlert} onClick={this.handleAlertClose} message={this.state.alertMessage} severity={this.state.alertSeverity}/>

						  	<Grid container spacing={3}>
								<Grid item xs={8}>
									<p style={{marginTop: 10, marginBottom: 5}}>Showing {totalCount} Logs in the database.</p>
								</Grid>
								<Grid item xs={4}>
									<div className="align-right">
										<SearchBox value={searchQuery} onChange={this.handleSearch} />
									</div>
								</Grid>
							</Grid>
						</CardContent>

						<div className="tableContainerBody">
							<HistoryTable
								history={history}
								sortColumn={sortColumn}
								onDelete={this.handleDelete}
								onClick={this.handleClickOpen}
								onSort={this.handleSort}
							/>
						</div>
						
						<TablePagination
							component="div"
							count={totalCount}
							page={currentPage}
							onChangePage={this.handleChangePage}
							rowsPerPage={pageSize}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</Card>
				</Grid>
			</Grid>
		</React.Fragment>
    );
  }
}

export default History;
