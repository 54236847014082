import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import * as userService from "../services/userService";
import auth from "../services/authService";
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class RegisterForm extends Form {
  state = {
    data: { username: "", email: "", password: "", firstName: "", lastName: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
		.required()
		.label("Username"),
	email: Joi.string()
		.required()
		.email()
		.label("Email"),
    password: Joi.string()
      .required()
      .min(5)
      .label("Password"),
    firstName: Joi.string()
      .required()
	  .label("First Name"),
	lastName: Joi.string()
      .required()
      .label("Last Name"),
  };

  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
	return (
		<React.Fragment>
			<Container maxWidth="sm">
				<Card style={{ marginTop: 20 }}>
					<CardContent>
						<h1>Register</h1>
						<form onSubmit={this.handleSubmit}>
							{this.renderInput("firstName", "First Name")}
							{this.renderInput("lastName", "Last Name")}
							{this.renderInput("username", "Username")}
							{this.renderInput("email", "Email")}
              				{this.renderInput("password", "Password", false, "password")}
							{this.renderButton("Register")}
						</form>
					</CardContent>
				</Card>
			</Container>
		</React.Fragment>
	);
  }
}

export default RegisterForm;
