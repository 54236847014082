import http from "./httpService";
import { apiUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const apiEndpoint = `${api}/lists`;
const teamsEndpoint = `${api}/teams/league`;

function listUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getLists() {
  return http.get(apiEndpoint);
}

export function getTeams(league, listId) {
	const endpoint = `${teamsEndpoint}/${league}/${listId}`;
	return http.get(endpoint);
}

export function getList(listId) {
  return http.get(listUrl(listId));
}

export function saveList(list) {
	if (list._id) {
		const body = { ...list };
		delete body._id;
		console.log('Updating list...');
		return http.put(listUrl(list._id), body);
	}

	console.log('Creating list...');
	return http.post(apiEndpoint, list);
}

export function deleteList(listId) {
  return http.delete(listUrl(listId));
}
