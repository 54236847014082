import React from "react";
import TextField from '@material-ui/core/TextField';
  
export default function Select({ name, value, label, options, error, ...rest }) {
	return (
		<TextField
			name={name} 
			id={name} 
			{...rest}
			select
			label={label}
			SelectProps={{ native: true }}
			variant="outlined"
			style={{ marginBottom: 20}}
			fullWidth
			value={value}
		>
			{options.map((option) => (
			<option key={option._id} value={option._id} disabled={option.disabled}>
				{option.name}
			</option>
			))}
		</TextField>
	);
  }