import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import theme from './theme';
import registerServiceWorker from "./registerServiceWorker";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import "font-awesome/css/font-awesome.css";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
		{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
		<CssBaseline />
      	<App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
registerServiceWorker();
