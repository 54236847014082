import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ListsTable from "./listsTable";
import ListGroup from "./common/listGroup";
import { getLists, deleteList } from "../services/listService";
import { paginate } from "../utils/paginate";
import _ from "lodash";
import SearchBox from "./searchBox";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TwitterIcon from '@material-ui/icons/Twitter';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TransitionAlerts from "./common/alerts";
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from "./common/loading";
import { saveHistory } from '../services/historyService';
import auth from "../services/authService";

class Lists extends Component {
	state = {
		sites: [],
		leagues: [],
		lists: [],
		currentPage: 0,
		pageSize: 10,
		searchQuery: "",
		selectedSite: null,
		selectedLeague: null,
		sortColumn: { path: "name", order: "asc" },
		open: false,
		openAlert: false,
		selectedList: null,
		alertMessage: null,
		alertSeverity: 'info', // error, warning, info, success
		loading: true
	};

	async componentDidMount() {
		const { data: lists } = await getLists();
		const siteList = [{ _id: "", site: "All Sites" }, ...lists];
		const leagueList = [{ _id: "", league: "All Leagues" }, ...lists];

		let availableSites = [];
		let mergedSiteList = [];
		let availableLeagues = [];
		let mergedLeagueList = [];
		
		siteList.forEach((list) => {
			if(availableSites.indexOf(list.site) === -1 && list.site !== undefined) {
				availableSites.push(list.site);
				mergedSiteList.push({_id: list._id, name: list.site});
			}
		});

		leagueList.forEach((list) => {
			if(availableLeagues.indexOf(list.league) === -1 && list.league !== undefined) {
				availableLeagues.push(list.league);
				mergedLeagueList.push({_id: list._id, name: list.league});
			}
		});

		this.setState({ lists, selectedList: lists[0], sites: mergedSiteList, leagues: mergedLeagueList, loading: false });
	}

	handleDelete = async list => {
		const originalLists = this.state.lists;
		const selectedList = this.state.selectedList;

		const alertMessage = "The List was removed from the Database.";
		const alertSeverity = 'success';
		const lists = originalLists.filter(l => l._id !== selectedList._id);
		this.setState({ lists });

		try {
		await deleteList(selectedList._id);

		const user = auth.getCurrentUser();
		const history = {
				userId: user._id,
				contentId: selectedList._id,
				action: 'DELETE',
				payload: JSON.stringify(selectedList),
				contentType: 'List',
			}
			await saveHistory(history);

		} catch (ex) {
		if (ex.response && ex.response.status === 404)
			toast.error("This List has already been deleted.");

		this.setState({ lists: originalLists });
		}
		
		this.setState({ open: false, openAlert: true, alertMessage, alertSeverity });
	};

	handleSiteSelect = site => {
		if (site._id) {
			this.setState({ selectedSite: site, searchQuery: "", currentPage: 0 });
		} else {
			this.setState({ selectedSite: null, searchQuery: "", currentPage: 0 });
		}
	};

	handleLeagueSelect = league => {
		if (league._id) {
			this.setState({ selectedLeague: league, selectedSite: null, searchQuery: "", currentPage: 0 });
		} else {
			this.setState({ selectedLeague: null, selectedSite: null, searchQuery: "", currentPage: 0 });
		}
	};

	handleSearch = query => {
		this.setState({ searchQuery: query, selectedLeague: null, selectedSite: null, currentPage: 0 });
	};

	handleSort = sortColumn => {
		this.setState({ sortColumn });
	};

	handleChangePage = (event, page) => {
		this.setState({ currentPage: page });
	};

	handleChangeRowsPerPage = (event) => {
		const pageSize = parseInt(event.target.value);
		this.setState({ pageSize: pageSize, selectedLeague: null, selectedSite: null, currentPage: 0 });
	};

	handleClickOpen = list => {
		this.setState({ open: true, selectedList: list });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleAlertOpen = list => {
		this.setState({ openAlert: true });
	};

	handleAlertClose = () => {
		this.setState({ openAlert: false });
	};

	getPagedData = () => {
		const {
		pageSize,
		currentPage,
		sortColumn,
		selectedSite,
		selectedLeague,
		searchQuery,
		lists: allLists
		} = this.state;

		let filtered = allLists;
		if (searchQuery) {
			filtered = allLists.filter(l =>
			l.name.toLowerCase().startsWith(searchQuery.toLowerCase())
			);
		} else if (selectedSite) {
			filtered = allLists.filter(l => l.site === selectedSite.name);
		} else if (selectedLeague) {
			filtered = allLists.filter(l => l.league === selectedLeague.name);
		}

		const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

		const lists = paginate(sorted, currentPage, pageSize);

		return { totalCount: filtered.length, data: lists };
	};

	render() {
		const { length: count } = this.state.lists;
		const { pageSize, currentPage, sortColumn, searchQuery, loading } = this.state;

		let isAdmin = false;
		const user = auth.getCurrentUser();
		
		if (user && user.isAdmin) {
			isAdmin = true;
		}

		if (loading) {
			return (
				<Loading></Loading>
			);
		} else if (count === 0) {
			return (
				<React.Fragment>
					<p>There are no Lists in the database.</p>
					{isAdmin &&
						<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/lists/new">
							New List
						</Button>
					}
				</React.Fragment>
			);
		}

		const { totalCount, data: lists } = this.getPagedData();
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<h1><TwitterIcon/> Twitter Lists</h1>
					</Grid>
					{isAdmin &&
						<Grid item xs={6}>
							<div className="align-right">
								<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/lists/new">
									New List
								</Button>
							</div>
						</Grid>
					}
				</Grid>

				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<ListGroup
							items={this.state.sites}
							selectedItem={this.state.selectedSite}
							onItemSelect={this.handleSiteSelect}
							filterName="Site"
						/>

						<ListGroup
							items={this.state.leagues}
							selectedItem={this.state.selectedLeague}
							onItemSelect={this.handleLeagueSelect}
							filterName="League"
						/>
					</Grid>
					<Grid item md={9} xs={12}>
						<Card style={{marginBottom: 20}}>
							<CardContent>
								
								<TransitionAlerts open={this.state.openAlert} onClick={this.handleAlertClose} message={this.state.alertMessage} severity={this.state.alertSeverity}/>

								<Grid container spacing={3}>
									<Grid item xs={8}>
										<p style={{marginTop: 10, marginBottom: 5}}>Showing {totalCount} Lists in the database.</p>
									</Grid>
									<Grid item xs={4}>
										<div className="align-right">
											<SearchBox value={searchQuery} onChange={this.handleSearch} />
										</div>
									</Grid>
								</Grid>
							</CardContent>

							<div className="tableContainerBody">
								<ListsTable
									lists={lists}
									sortColumn={sortColumn}
									onDelete={this.handleDelete}
									onClick={this.handleClickOpen}
									onSort={this.handleSort}
									open={this.state.open}
									onClose={this.handleClose}
								/>
							</div>
							
							<TablePagination
								component="div"
								count={totalCount}
								page={currentPage}
								onChangePage={this.handleChangePage}
								rowsPerPage={pageSize}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
							/>
						</Card>
					</Grid>
				</Grid>

				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Are your sure?</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							This will permanently delete {this.state.selectedList.name}.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleDelete} color="primary" autoFocus>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default Lists;
