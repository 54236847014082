import React, { Component } from "react";
import auth from "../services/authService";
import { Link } from "react-router-dom";
import Table from "./common/table";
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';


class HistoryTable extends Component {
	columns = [
		{ path: "userId", label: "User" },
		{
			path: "contentId",
			label: "Content Id",
			content: history => <Link to={`/history/${history.contentId}`}>{history.contentId}</Link>
		},
		{ path: "action", label: "Action" },
		{ path: "contentType", label: "Content Type" },
		{ path: "date", label: "Date" },
	];

	viewColumn = {
		key: "view",
		content: history => (
			<Button className="btn-sm" component={Link} to={`/history/${history.contentId}`}>
				<VisibilityIcon style={{fontSize: 20}}/>
			</Button>
		),
		class: "actionColumn"
	};

	
	constructor() {
		super();
		const user = auth.getCurrentUser();
		if (user && user.isAdmin) {
			this.columns.push(this.viewColumn);
		}
	}

  render() {
    const { history, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={history}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default HistoryTable;
