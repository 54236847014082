import React from "react";
import { Link } from "react-router-dom";
import { Prompt } from 'react-router'
import Joi from "joi-browser";
import Form from "./common/form";
import { getList, saveList, getTeams, deleteList } from "../services/listService";
import { getHandles, getHandle } from "../services/handleService";
import { apiUrl } from "../config.json";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import History from "./common/history";
import { getHistory, saveHistory } from '../services/historyService';
import { getUser } from '../services/userService';
import auth from "../services/authService";
import TransitionAlerts from "./common/alerts";
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

class ListForm extends Form {
	state = {
		data: {
			name: "",
			team: "",
			externalId: "",
			location: "",
			nickName: "",
			abbrev: "",
			mediumName: "",
			league: "MLB",
			site: "www.cbssports.com",
			sportEvent: "",
			handles: {},
		},
		errors: {},
		allValues: [],
		defaultValues: [],
		history: [],
		siteOptions: [
			{
				_id: 'www.cbssports.com',
				name: 'www.cbssports.com'
			},
			{
				_id: 'www.sportsline.com',
				name: 'www.sportsline.com'
			}
		],
		leagueOptions: [
			{ _id: 'ARGE', name: 'ARGE' },
            { _id: 'BRAZ', name: 'BRAZ' },
            { _id: 'BUND', name: 'BUND' },
            { _id: 'CHLG', name: 'CHLG' },
            { _id: 'EPL', name: 'EPL' },
            { _id: 'FMF', name: 'FMF' },
            { _id: 'FRAN', name: 'FRAN' },
            { _id: 'HOLL', name: 'HOLL' },
            { _id: 'LIGA', name: 'LIGA' },
            { _id: 'MLB', name: 'MLB' },
            { _id: 'MLS', name: 'MLS' },
            { _id: 'NBA', name: 'NBA' },
            { _id: 'NCAAB', name: 'NCAAB' },
            { _id: 'NCAAF', name: 'NCAAF' },
            { _id: 'NCF1AA', name: 'NCF1AA' },
            { _id: 'NFL', name: 'NFL' },
            { _id: 'NHL', name: 'NHL' },
            { _id: 'NWSL', name: 'NWSL' },
            { _id: 'SCOT', name: 'SCOT' },
            { _id: 'SERI', name: 'SERI' },
            { _id: 'UEFA', name: 'UEFA' },
            { _id: 'WCS', name: 'WCS' },
            { _id: 'WNBA', name: 'WNBA' },
		],
		teams: [
			{
				_id: '',
				name: ''
			}
		],
		teamsMetadata: [],
		openAlert: false,
		selectedList: null,
		alertMessage: null,
		alertSeverity: 'info', // error, warning, info, success
		shouldBlockNavigation: false,
	};

	schema = {
		_id: Joi.string(),
		name: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("Name"),
		league: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("League"),
		team: Joi.string()
			.required()
			.min(1)
			.max(250)
			.label("Team"),
		externalId: Joi.number()
			.required()
			.label("External ID"),
		location: Joi.string()
			.min(1)
			.max(250)
			.label("Location"),
		nickName: Joi.string()
			.min(1)
			.max(250)
			.label("Nickname"),
		abbrev: Joi.string()
			.min(1)
			.max(25)
			.label("Abbrev"),
		mediumName: Joi.string()
			.min(1)
			.max(250)
			.label("MediumName"),
		site: Joi.string()
			.min(1)
			.max(250)
			.label("Site"),
		sportEvent: Joi.string()
			.required()
			.min(1)
			.max(250)
			.label("Sport/Event"),
		handles: Joi.array()
			.label("Handles"),
	};

	async populateHandles() {
		try{
			const { data: allValues } = await getHandles();
			this.setState({ allValues });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async populateTeams() {
		try{
			let league = this.state.data.league;
			const listId = this.props.match.params.id;
			
			if (listId === "new") {
				league = this.state.leagueOptions[0].name;
			};

			await this.handleGetTeams(league);

		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async handleGetTeams(league, teamChange = false){
		try{
			const listId = this.props.match.params.id;
			const { data: teams } = await getTeams(league, listId);

			const mappedTeams = await Promise.all(teams.data.map(async (team) => {
				return this.mapTeamToViewModel(team);
			}));
			
			if (mappedTeams.length === 0) {
				mappedTeams.unshift({
					_id: 'No available teams',
					name: 'No available teams',
					value: 'No available teams',
					disabled: true,
				});	
			} else {
				mappedTeams.unshift({
					_id: '',
					name: '',
				});
			}

			this.setState({ teams: mappedTeams });

			if (teamChange) {
				const { data } = { ...this.state };
				data.externalId = '';
				data.location = '';
				data.mediumName = '';
				data.nickName = '';
				data.abbrev = '';

				this.setState({ data });
			}

		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	};

	handleTeamChange(teamSelected){
		try{
			teamSelected = parseInt(teamSelected);
			const teamData = this.state.teams.find((team) => {
				return team._id === teamSelected;
			});

			if (teamData) {
				const { data } = { ...this.state };
				data.externalId = teamData._id;
				data.location = teamData.location;
				data.mediumName = teamData.mediumName;
				data.nickName = teamData.nickName;
				data.abbrev = teamData.abbrev;
				
				this.setState({ data, errors: {} });
			}

		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	};

	async populateHistory() {
		try{
			const listId = this.props.match.params.id;
			if (listId === "new") return;

			const { data: history } = await getHistory(listId);
			const dataHistory = await Promise.all(history.map(async (element) => {
				const objectArray = Object.values(element);
				
				try {
					const { data: userId } = await getUser(objectArray[1]);
					if (userId) {
						objectArray[1] = userId.email;
					}
				} catch (error) {
					console.log(error);
				}

				return objectArray;
			}));

			this.setState({ history: dataHistory });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async populateList() {
		try {
			const listId = this.props.match.params.id;
			if (listId === "new") return;
			
			const { data: list } = await getList(listId);
			this.setState({ data: this.mapToViewModel(list) });
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 404)
				this.props.history.replace("/not-found");
		}
	}

	async populateDefaultHandles() {
		try {
			const listId = this.props.match.params.id;
			if (listId === "new") return;

			const { data: list } = await getList(listId);
			
			if (list.handles) {
				const listHandles = list.handles;
				const handlesInfo = await Promise.all(listHandles.map( async (handle) =>{
					const { data: handleInfo } = await getHandle(handle);
					return handleInfo[0];
				}));
				this.setState({ defaultValues: handlesInfo });
			}

		} catch (ex) {
			console.log('Handles not found: ', ex);
		}
	}

	async componentDidMount() {
		await this.populateHandles();
		await this.populateHistory();
		await this.populateList();
		await this.populateDefaultHandles();
		await this.populateTeams();
	}

	handleAlertClose = () => {
		this.setState({ openAlert: false });
	};

	mapToViewModel(list) {
		return {
			_id: list._id,
			name: list.name,
			team: list.team,
			externalId: list.externalId,
			location: list.location,
			nickName: list.nickName,
			abbrev: list.abbrev,
			mediumName: list.mediumName,
			league: list.league,
			site: list.site,
			sportEvent: list.sportEvent,
			handles: list.handles,
		};
	}

	mapTeamToViewModel(team) {

		const _id = (team.teamId) ? team.teamId : '-';
		const nickName = (team.nickName && team.nickName !== '') ? team.nickName : '-';
		const abbrev = (team.abbrev && team.abbrev !== '') ? team.abbrev : '-';
		const location = (team.location) ? team.location : '-';
		const mediumName = (team.mediumName) ? team.mediumName : '-';
		
		let name = (team.mediumName) ? `${team.mediumName} ` : '';
		name += (team.nickName) ? `${team.nickName} ` : '';
		name += `(${abbrev})`;
		
		return {
			_id,
			name,
			nickName,
			abbrev,
			mediumName,
			location,
		};
	}

	doSubmit = async () => {
		const { data } = this.state;
		let alertMessage = "The List was added.";
		let alertSeverity = 'success';

		try {
			const { data: savedList } = await saveList(this.state.data);

			const contentId = this.props.match.params.id;

			const user = auth.getCurrentUser();
			
			const history = {
				userId: user._id,
				contentId,
				action: 'EDIT',
				payload: JSON.stringify(data),
				contentType: 'List',
			}

			if (contentId === "new") {
				history.contentId = savedList._id;
				history.action = 'NEW';
			}

			await saveHistory(history);

			alertMessage = "List saved!";
			alertSeverity = 'success';
			this.setState({ openAlert: true, alertMessage, alertSeverity, shouldBlockNavigation: false });

			// this.props.history.push("/lists");
		} catch (error) {
			alertMessage = "A List with same name, or associated Team already exists, please use a different name and Team selection.";
			alertSeverity = 'error';
			this.setState({ openAlert: true, alertMessage, alertSeverity, shouldBlockNavigation: true });
		}
	};

  	render() {
		const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
		const listExternalId = this.state.data.externalId;
		const listApi = `${api}/feed/?lists=${listExternalId}&limit=50`;

		return (
			<Grid container spacing={2} style={{marginTop: 20}}>
				<Grid item xs={12}>
					<Card style={{marginBottom: 20}}>
						<CardContent>
							<Grid container>
								<Grid item xs={6}>
									<div className="title-container">
										<h1>List Form</h1>
									</div>
								</Grid>
								<Grid item xs={6} style={{textAlign: "right"}}>
									<Chip
										avatar={<Avatar className="chip-name">API</Avatar>}
										label={listApi}
										variant="outlined"
									/>
								</Grid>
							</Grid>

							
							<Prompt
								when={this.state.shouldBlockNavigation}
								message="You have unsaved changes, are you sure you want to leave?"
							/>

							<TransitionAlerts open={this.state.openAlert} onClick={this.handleAlertClose} message={this.state.alertMessage} severity={this.state.alertSeverity}/>
							
							<Button variant="contained" style={{marginBottom:20}} startIcon={<KeyboardBackspaceIcon/>} component={Link} to="/lists">
								Back to Lists
							</Button>

							<form onSubmit={this.handleSubmit}>
								{this.renderInput("name", "Name")}
								
								<Grid container spacing={2}>
									<Grid item xs={6}>
										{this.renderSelect("site", "Site", this.state.siteOptions)}
									</Grid>
									<Grid item xs={6}>
										{this.renderInput("sportEvent", "sportEvent")}
									</Grid>
								</Grid>
								
								<Grid container spacing={2}>
									<Grid item xs={6}>
										{this.renderSelect("league", "League", this.state.leagueOptions)}
									</Grid>
									<Grid item xs={6}>
										{this.renderSelect("team", "Team", this.state.teams)}
									</Grid>
								</Grid>
								
								<Card style={{marginBottom: 20, padding: 20, paddingBottom: 0}} className="card-bg-gray">
									<h3 style={{marginTop:0}} className="fancy-title"> 
										<Tooltip title="The fields will autopopulate after Team selection." arrow>
											<LockIcon/>
										</Tooltip>
										Team Fields
									</h3>
									
									{this.renderInput("nickName", "Nickname", true)}

									<Grid container spacing={2}>
										<Grid item xs={6}>
											{this.renderInput("abbrev", "Abbrev", true)}
										</Grid>
										<Grid item xs={6}>
											{this.renderInput("mediumName", "MediumName", true)}
										</Grid>
									</Grid>
									
									<Grid container spacing={2}>
										<Grid item xs={6}>
											{this.renderInput("externalId", "External Id", true)}
										</Grid>
										<Grid item xs={6}>
											{this.renderInput("location", "Location", true)}
										</Grid>
									</Grid>
								</Card>
								

								<Box className="no-margin-container handle-box">
									{this.renderAutocompleteInput("handles", "Handles")}
								</Box>

								{this.renderButton("Save")}
								{this.renderButton("Cancel", "link", "/lists")}
								
								<Button variant="contained" style={{marginTop:0}} startIcon={<KeyboardBackspaceIcon/>} component={Link} to="/lists">
									Back to Lists
								</Button>
							</form>
						</CardContent>
					</Card>

					{this.props.match.params.id !== "new" && this.state.history.length !== 0 &&
						<div className="history-content">
							<History data={this.state.history}/>
						</div>
					}
				</Grid>
			</Grid>
		);
  	}
}

export default ListForm;
