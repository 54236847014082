import React, { Component } from "react";
import { Link } from "react-router-dom";
import UsersTable from "./usersTable";
import { getUsers } from "../services/userService";
import { paginate } from "../utils/paginate";
import _ from "lodash";
import SearchBox from "./searchBox";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TablePagination from '@material-ui/core/TablePagination';

class Users extends Component {
  state = {
	users: [],
    currentPage: 0,
    pageSize: 10,
    searchQuery: "",
	sortColumn: { path: "name", order: "asc" },
	open: false,
	openAlert: false,
	selectedList: null,
	alertMessage: null,
	alertSeverity: 'info', // error, warning, info, success
  };

  async componentDidMount() {
    const { data: users } = await getUsers();

	this.setState({ users });
  }

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 0 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  handleChangeRowsPerPage = (event) => {
	const pageSize = parseInt(event.target.value);
	this.setState({ pageSize: pageSize, currentPage: 0 });
  };

  handleClickOpen = list => {
	this.setState({ open: true, selectedList: list });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      users: allUsers
    } = this.state;

    let filtered = allUsers;
    if (searchQuery) {
		filtered = allUsers.filter(user =>
			user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) || user.lastName.toLowerCase().includes(searchQuery) || user.username.toLowerCase().includes(searchQuery.toLowerCase()) || user.email.toLowerCase().includes(searchQuery.toLowerCase())
		);
	}

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const users = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: users };
  };

  render() {
    const { length: count } = this.state.users;
	const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

	if (count === 0) {
		return (
			<React.Fragment>
				<p>There are no Users in the database.</p>
				<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/users/new">
					Add User
				</Button>
			</React.Fragment>
		);
	}

	const { totalCount, data: users } = this.getPagedData();

	return (
		<React.Fragment>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<h1>User Profiles</h1>
				</Grid>
				<Grid item xs={6}>
					<div className="align-right">
						<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/users/new">
							Add User
						</Button>
					</div>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card style={{marginBottom: 20}}>
      					<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<p style={{marginTop: 10, marginBottom: 5}}>Showing {totalCount} Users in the database.</p>
								</Grid>
								<Grid item xs={4}>
									<div className="align-right">
										<SearchBox value={searchQuery} onChange={this.handleSearch} />
									</div>
								</Grid>
							</Grid>
						</CardContent>

						<div className="tableContainerBody">
							<UsersTable
								users={users}
								sortColumn={sortColumn}
								onClick={this.handleClickOpen}
								onSort={this.handleSort}
							/>
						</div>
						
						<TablePagination
							component="div"
							count={totalCount}
							page={currentPage}
							onChangePage={this.handleChangePage}
							rowsPerPage={pageSize}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</Card>
				</Grid>
			</Grid>

		</React.Fragment>
    );
  }
}

export default Users;
