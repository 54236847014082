import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + LISTBOX_PADDING,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
		ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
		return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
		return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

  	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
		<OuterElementContext.Provider value={other}>
			<VariableSizeList
			itemData={itemData}
			height={getHeight() + 2 * LISTBOX_PADDING}
			width="100%"
			ref={gridRef}
			outerElementType={OuterElementType}
			innerElementType="ul"
			itemSize={(index) => getChildSize(itemData[index])}
			overscanCount={5}
			itemCount={itemCount}
			>
			{renderRow}
			</VariableSizeList>
		</OuterElementContext.Provider>
		</div>
	);
});

ListboxComponent.propTypes = {
	children: PropTypes.node,
};

const AutocompleteInput = ({ name, label, error, ...rest }) => {  
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

  	return (
		<FormControl fullWidth>
			<Autocomplete
				multiple
				required
				id={name}
				{...rest}
				disableCloseOnSelect
				ListboxComponent={ListboxComponent}
				getOptionLabel={(option) => `${option.name} (${option.handleId})`}
				renderOption={(option, { selected }) => (
					<React.Fragment>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							style={{ marginRight: 8 }}
							// checked={(option.selected) ? option.selected : selected}
							checked={selected}
						/>
						{`${option.name} (${option.handleId})`}
					</React.Fragment>
				)}
				renderInput={(params) => (
					<TextField {...params} variant="outlined" label={label} placeholder={label} />
				)}
			/>
			{error && <div className="input-error">{error}</div>}
		</FormControl>	
	);
}

export default AutocompleteInput;
