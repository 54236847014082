import http from "./httpService";
import { apiUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const apiEndpoint = `${api}/events`;

function eventUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getEvents() {
  return http.get(apiEndpoint);
}

export function getEvent(eventId) {
  return http.get(eventUrl(eventId));
}

export function saveEvent(event) {
	const body = { ...event };
	
	if (body.description === '') {
		delete body.description;
	}
		
	if (event._id) {
		delete body._id;
		console.log('Updating event...');
		return http.put(eventUrl(event._id), body);
	}

	console.log('Creating event...');
	return http.post(apiEndpoint, body);
}

export function deleteEvent(eventId) {
  return http.delete(eventUrl(eventId));
}
