import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedRoute = ({ path, validateAdmin, component: Component, render, ...rest }) => {

	const user = auth.getCurrentUser();
	const { location } = {...rest};
	let isAdmin = true;
	let canEdit = true;
	
	if (user && !user.isAdmin && validateAdmin) {
		isAdmin = false;
		canEdit = false;
	}

	if (user && location.pathname.includes(user._id)){
		isAdmin = true;
		canEdit = true;
	}
	
	return (
		<Route
		{...rest}
		render={props => {
			if (!user || !isAdmin || !canEdit) {
				return <Redirect to={{pathname: "/login", state: { from: props.location }}}/>;
			} else {
				return Component ? <Component {...props} /> : render(props);
			}
		}}
		/>
	);
};

export default ProtectedRoute;
