import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getHandles, deleteHandle } from "../services/handleService";
import { paginate } from "../utils/paginate";
import _ from "lodash";
import SearchBox from "./searchBox";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Loading from "./common/loading";
import TransitionAlerts from "./common/alerts";
import TablePagination from '@material-ui/core/TablePagination';
import { saveHistory } from '../services/historyService';
import auth from "../services/authService";

class Handles extends Component {
  	state = {
		handles: [],
		leagues: [],
		currentPage: 0,
		pageSize: 15,
		searchQuery: "",
		sortColumn: { path: "name", order: "asc" },
		open: false,
		openAlert: false,
		selectedHandle: null,
		alertMessage: null,
		alertSeverity: 'info', // error, warning, info, success
		anchorEl: null,
		loading: true
  	};

  async componentDidMount() {
	const { data: handles } = await getHandles();

	const leagueList = [{ _id: "", name: "All Leagues", league: "All Leagues" }, ...handles];

	let availableLeagues = [];
	let mergedLeagueList = [];
	
	leagueList.forEach((handle) => {
		if(availableLeagues.indexOf(handle.league) === -1 && handle.league !== undefined) {
			availableLeagues.push(handle.league);
			mergedLeagueList.push({_id: handle._id, name: handle.league});
		}
	});

	this.setState({
		handles,
		selectedHandle: handles[0],
		leagues: mergedLeagueList,
		loading: false
	});
  }

  handleDelete = async handle => {
	const originalHandles = this.state.handles;
	const selectedHandle = this.state.selectedHandle;
	let alertMessage = "The Handle was removed from the Database.";
	let alertSeverity = 'success';
    const handles = originalHandles.filter(h => h._id !== selectedHandle._id);
	this.setState({ handles });

    try {
		await deleteHandle(selectedHandle._id);
		this.setState({ open: false, openAlert: true, alertMessage, alertSeverity });
		toast.success(alertMessage);

		const user = auth.getCurrentUser();
	  	const history = {
			userId: user._id,
			contentId: selectedHandle._id,
			action: 'DELETE',
			payload: JSON.stringify(selectedHandle),
			contentType: 'Handle',
		}
		await saveHistory(history);
    } catch (ex) {
		if (ex.response && ex.response.status === 404) {
			alertMessage = "This Handle has already been deleted.";
			alertSeverity = 'error';
			this.setState({ open: false, openAlert: true, alertMessage, alertSeverity });
		}

		this.setState({ handles: originalHandles });
	}
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 0 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  handleChangeRowsPerPage = (event) => {
	const pageSize = parseInt(event.target.value);
	this.setState({ pageSize: pageSize, currentPage: 0 });
  };

  handleClickOpen = handle => {
	this.setState({ open: true, selectedHandle: handle });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAlertOpen = list => {
	this.setState({ openAlert: true });
  };

  handleAlertClose = () => {
    this.setState({ openAlert: false });
  };

  handleMoreMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      handles: allHandles
    } = this.state;

    let filtered = allHandles;
    if (searchQuery) {
		filtered = allHandles.filter(h =>
			h.name.toLowerCase().includes(searchQuery.toLowerCase()) || h.handleId.toString().includes(searchQuery) || h.fullName.toLowerCase().includes(searchQuery.toLowerCase())
		);
	}

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const handles = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: handles };
  };

  render() {
    const { length: count } = this.state.handles;
	const { pageSize, currentPage, searchQuery, loading } = this.state;

	let isAdmin = false;
	const user = auth.getCurrentUser();

    if (user && user.isAdmin) {
		isAdmin = true;
	}

	if (loading) {
		return (
			<Loading></Loading>
		);
	} else if (count === 0) {
		return (
			<React.Fragment>
				<p>There are no Handles in the database.</p>
				{isAdmin &&
					<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/handles/new">
						New Handle
					</Button>
				}
			</React.Fragment>
		);
	}
		

	const { totalCount, data: handles } = this.getPagedData();
	const paginationOptions = [15,30,45,90];

	return (
		<React.Fragment>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<h1><AlternateEmailIcon/> Twitter Handles</h1>
				</Grid>
				{isAdmin &&
					<Grid item xs={6}>
						<div className="align-right">
							<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/handles/new">
								New Handle
							</Button>
						</div>
					</Grid>
  				}
			</Grid>

			<Grid container spacing={2}>
        		<Grid item xs={12}>
					<div style={{marginBottom: 20}}>
      					<div>
						  	
							<TransitionAlerts open={this.state.openAlert} onClick={this.handleAlertClose} message={this.state.alertMessage} severity={this.state.alertSeverity}/>

						  	<Grid container spacing={3} style={{marginBottom:20}}>
								<Grid item xs={8}>
									<p style={{marginTop: 10, marginBottom: 5}}>Showing {totalCount} Handles in the database.</p>
								</Grid>
								<Grid item xs={4}>
									<div className="align-right">
										<SearchBox value={searchQuery} onChange={this.handleSearch} />
									</div>
								</Grid>
							</Grid>

							<Grid container spacing={2}>
								{handles.map((handle) => {
									return (
										<Grid item md={4} xs={12} key={handle.handleId}>
											<Card style={{marginBottom: 20}} className="bg-gray">
												{isAdmin &&
													<CardHeader
														avatar={<Link to={`/handles/${handle.handleId}`}><Avatar alt={handle.fullName} src={(handle.profileImage) ? handle.profileImage : ''} /></Link>}
														action={
															<React.Fragment>
																<IconButton className="btn-sm-5" component={Link} to={`/handles/${handle.handleId}`}>
																	<EditIcon className="btn-black" style={{fontSize: 20}}/>
																</IconButton>
																<IconButton className="btn-sm-5" aria-label="delete" onClick={() => this.handleClickOpen(handle)}>
																	<DeleteIcon className="btn-danger" style={{fontSize: 20}}/>
																</IconButton>
															</React.Fragment>
														}
														title={`${handle.name} - (ID: ${handle.handleId})`}
														subheader={handle.fullName}
													/>
												}
												{!isAdmin &&
													<CardHeader
														avatar={<Avatar alt={handle.fullName} src={(handle.profileImage) ? handle.profileImage : ''} />}
														title={`${handle.name} - (ID: ${handle.handleId})`}
														subheader={handle.fullName}
													/>
												}
											</Card>
										</Grid>
									)
								})}
							</Grid>
						</div>

						<TablePagination
							component="div"
							count={totalCount}
							page={currentPage}
							onChangePage={this.handleChangePage}
							rowsPerPage={pageSize}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
							rowsPerPageOptions={paginationOptions}
						/>
					</div>
				</Grid>
			</Grid>

			<Dialog
				open={this.state.open}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Are your sure?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						This will permanently delete {this.state.selectedHandle.name}.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={this.handleDelete} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
    );
  }
}

export default Handles;
