import http from "./httpService";
import { apiUrl } from "../config.json";

const api = (window.REACT_APP_API_URL) ? window.REACT_APP_API_URL : apiUrl;
const apiEndpoint = `${api}/users`;

function userUrl(id) {
	return `${apiEndpoint}/${id}`;
}
  
export function getUsers() {
	return http.get(apiEndpoint);
}

export function getUser(userId) {
	return http.get(userUrl(userId));
}

export function saveUser(user) {
	const body = { ...user };
	if (user._id) {
	  	delete body._id;
	  	console.log('Saving...');
	  	// return http.put(listUrl(list._id), body);
	} 
		
	return http.post(apiEndpoint, body);
  
  }

export function register(user) {
	return http.post(apiEndpoint, {
		firstName: user.firstName,
		lastName: user.lastName,
		username: user.username,
		email: user.email,
		password: user.password,
	});
}
