import React from "react";
import Form from "./common/form";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getHistory } from '../services/historyService';
import { getUser } from '../services/userService';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class HistoryForm extends Form {
	state = {
		history: [],
		contentType: ""
	};

	async populateHistory() {
		try{
			const contentId = this.props.match.params.id;
			const { data: history } = await getHistory(contentId);
			const dataHistory = await Promise.all(history.map(async (element) => {
				const objectArray = Object.values(element);
				
				try {
					const { data: userId } = await getUser(objectArray[1]);
					if (userId) {
						objectArray[1] = userId.email;
					}
				} catch (error) {
					console.log(error);
				}

				return objectArray;
			}));

			const contentType = dataHistory[0][5];

			this.setState({ history: dataHistory, contentType });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async componentDidMount() {
		await this.populateHistory();
	}

	render() {

		return (
			<Grid container spacing={2} style={{marginTop: 20}}>
				<Grid item xs={12}>
					<Card style={{marginBottom: 20}}>
      					<CardContent>

						  	<Button variant="contained" style={{marginBottom:20}} startIcon={<ArrowBackIcon/>} component={Link} to="/history/">
								Back to Activity Log
							</Button>

							<div className="title-container">
								<h1>{this.state.contentType} ID: {this.props.match.params.id}</h1>
							</div>

							{this.state.history.length !== 0 &&
								<React.Fragment>
									<div className="history-content">
										<TableContainer component={Paper}>
											<Table className="table">
												<TableHead>
													<TableRow>
														<TableCell>User</TableCell>
														<TableCell>Action</TableCell>
														<TableCell>Date</TableCell>
														<TableCell>Payload</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
												{this.state.history.map((row) => (
													<TableRow key={row[0]}>
														<TableCell>{row[1]}</TableCell>
														<TableCell>{row[3]}</TableCell>
														<TableCell>{row[6]}</TableCell>
														<TableCell>{row[4]}</TableCell>
													</TableRow>
												))}
												</TableBody>
											</Table>
										</TableContainer>
									</div>

									<Button variant="contained" style={{marginTop:20}} startIcon={<ArrowBackIcon/>} component={Link} to="/history/">
										Back to Activity Log
									</Button>
								</React.Fragment>
							}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		);
  	}
}

export default HistoryForm;
