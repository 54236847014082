import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import EventsTable from "./eventsTable";
import ListGroup from "./common/listGroup";
import { getEvents, deleteEvent } from "../services/eventService";
import { paginate } from "../utils/paginate";
import _ from "lodash";
import SearchBox from "./searchBox";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TransitionAlerts from "./common/alerts";
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from "./common/loading";
import { saveHistory } from '../services/historyService';
import auth from "../services/authService";
import { relative } from "joi-browser";

class Events extends Component {
	state = {
		sites: [],
		leagues: [],
		events: [],
		currentPage: 0,
		pageSize: 10,
		searchQuery: "",
		selectedSite: null,
		selectedLeague: null,
		sortColumn: { path: "name", order: "asc" },
		open: false,
		openAlert: false,
		selectedEvent: null,
		alertMessage: null,
		alertSeverity: 'info', // error, warning, info, success
		loading: true
	};

	async componentDidMount() {
		const { data: events } = await getEvents();
		const siteEvent = [{ _id: "", site: "All Sites" }, ...events];
		const leagueEvent = [{ _id: "", league: "All Leagues" }, ...events];

		let availableSites = [];
		let mergedSiteEvent = [];
		let availableLeagues = [];
		let mergedLeagueEvent = [];
		
		siteEvent.forEach((event) => {
			if(availableSites.indexOf(event.site) === -1 && event.site !== undefined) {
				availableSites.push(event.site);
				mergedSiteEvent.push({_id: event._id, name: event.site});
			}
		});

		leagueEvent.forEach((event) => {
			if(availableLeagues.indexOf(event.league) === -1 && event.league !== undefined) {
				availableLeagues.push(event.league);
				mergedLeagueEvent.push({_id: event._id, name: event.league});
			}
		});

		this.setState({ 
			events, 
			selectedEvent: events[0],
			sites: mergedSiteEvent,
			leagues: mergedLeagueEvent,
			loading: false
		});
	}

	handleDelete = async event => {
		const originalEvents = this.state.events;
		const selectedEvent = this.state.selectedEvent;

		const alertMessage = "The Event was removed from the Database.";
		const alertSeverity = 'success';
		const events = originalEvents.filter(l => l._id !== selectedEvent._id);
		this.setState({ events });

		try {
		await deleteEvent(selectedEvent._id);

		const user = auth.getCurrentUser();
		const history = {
				userId: user._id,
				contentId: selectedEvent._id,
				action: 'DELETE',
				payload: JSON.stringify(selectedEvent),
				contentType: 'Event',
			}
			await saveHistory(history);

		} catch (ex) {
		if (ex.response && ex.response.status === 404)
			toast.error("This Event has already been deleted.");

		this.setState({ events: originalEvents });
		}
		
		this.setState({ open: false, openAlert: true, alertMessage, alertSeverity });
	};

	handleSiteSelect = site => {
		if (site._id) {
			this.setState({ selectedSite: site, searchQuery: "", currentPage: 0 });
		} else {
			this.setState({ selectedSite: null, searchQuery: "", currentPage: 0 });
		}
	};

	handleLeagueSelect = league => {
		if (league._id) {
			this.setState({ selectedLeague: league, selectedSite: null, searchQuery: "", currentPage: 0 });
		} else {
			this.setState({ selectedLeague: null, selectedSite: null, searchQuery: "", currentPage: 0 });
		}
	};

	handleSearch = query => {
		this.setState({ searchQuery: query, selectedLeague: null, selectedSite: null, currentPage: 0 });
	};

	handleSort = sortColumn => {
		this.setState({ sortColumn });
	};

	handleChangePage = (event, page) => {
		this.setState({ currentPage: page });
	};

	handleChangeRowsPerPage = (event) => {
		const pageSize = parseInt(event.target.value);
		this.setState({ pageSize: pageSize, selectedLeague: null, selectedSite: null, currentPage: 0 });
	};

	handleClickOpen = event => {
		this.setState({ open: true, selectedEvent: event });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleAlertOpen = event => {
		this.setState({ openAlert: true });
	};

	handleAlertClose = () => {
		this.setState({ openAlert: false });
	};

	getPagedData = () => {
		const {
		pageSize,
		currentPage,
		sortColumn,
		selectedSite,
		selectedLeague,
		searchQuery,
		events: allEvents
		} = this.state;

		let filtered = allEvents;
		if (searchQuery) {
			filtered = allEvents.filter(l =>
			l.name.toLowerCase().startsWith(searchQuery.toLowerCase())
			);
		} else if (selectedSite) {
			filtered = allEvents.filter(l => l.site === selectedSite.name);
		} else if (selectedLeague) {
			filtered = allEvents.filter(l => l.league === selectedLeague.name);
		}

		const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

		const events = paginate(sorted, currentPage, pageSize);

		return { totalCount: filtered.length, data: events };
	};

	render() {
		const { length: count } = this.state.events;
		const { pageSize, currentPage, sortColumn, searchQuery, loading } = this.state;

		let isAdmin = false;
		const user = auth.getCurrentUser();
		
		if (user && user.isAdmin) {
			isAdmin = true;
		}

		if (loading) {
			return (
				<Loading></Loading>
			);
		} else if (count === 0) {
			return (
				<React.Fragment>
					<p>There are no Events in the database.</p>
					{isAdmin &&
						<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/events/new">
							New Event
						</Button>
					}
				</React.Fragment>
			);
		}

		const { totalCount, data: events } = this.getPagedData();
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<h1><EventNoteIcon/> Twitter Events</h1>
					</Grid>
					{isAdmin &&
						<Grid item xs={6}>
							<div className="align-right">
								<Button variant="contained" style={{marginTop:20}} endIcon={<AddIcon/>} component={Link} to="/events/new">
									New Event
								</Button>
							</div>
						</Grid>
					}
				</Grid>

				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<ListGroup
							items={this.state.sites}
							selectedItem={this.state.selectedSite}
							onItemSelect={this.handleSiteSelect}
							filterName="Site"
						/>

						<ListGroup
							items={this.state.leagues}
							selectedItem={this.state.selectedLeague}
							onItemSelect={this.handleLeagueSelect}
							filterName="League"
						/>
					</Grid>
					<Grid item md={9} xs={12}>
						<Card style={{marginBottom: 20}}>
							<CardContent>
								
								<TransitionAlerts open={this.state.openAlert} onClick={this.handleAlertClose} message={this.state.alertMessage} severity={this.state.alertSeverity}/>

								<Grid container spacing={3}>
									<Grid item xs={8}>
										<p style={{marginTop: 10, marginBottom: 5}}>Showing {totalCount} Events in the database.</p>
									</Grid>
									<Grid item xs={4}>
										<div className="align-right">
											<SearchBox value={searchQuery} onChange={this.handleSearch} />
										</div>
									</Grid>
								</Grid>
							</CardContent>

							<div className="tableContainerBody">
								<EventsTable
									events={events}
									sortColumn={sortColumn}
									onDelete={this.handleDelete}
									onClick={this.handleClickOpen}
									onSort={this.handleSort}
									open={this.state.open}
									onClose={this.handleClose}
								/>
							</div>
							
							<TablePagination
								component="div"
								count={totalCount}
								page={currentPage}
								onChangePage={this.handleChangePage}
								rowsPerPage={pageSize}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
							/>
						</Card>
					</Grid>
				</Grid>

				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Are your sure?</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							This will permanently delete {this.state.selectedEvent.name}.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleDelete} color="primary" autoFocus>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default Events;
