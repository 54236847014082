import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { toast } from "react-toastify";
import { searchHandle, getHandle, saveHandle } from "../services/handleService";
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import History from "./common/history";
import { getHistory, saveHistory } from '../services/historyService';
import { getUser } from '../services/userService';
import auth from "../services/authService";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

class HandleForm extends Form {
	state = {
		data: {
			name: "",
			handleId: "",
			fullName: "",
			profileImage: "",
		},
		errors: {},
		allValues: [],
		defaultValues: [],
		history: [],
		_id: "",
	};

	schema = {
		_id: Joi.string(),
		name: Joi.string()
			.required()
			.min(1)
			.max(50)
			.label("Name"),
		handleId: Joi.number()
			.required()
			.min(1)
			.max(999999999999999)
			.label("HandleId"),
		fullName: Joi.string()
			.min(1)
			.max(250)
			.label("Full Name"),
		profileImage: Joi.string()
			.min(1)
			.max(250)
			.label("Profile Image"),
	};

	async populateHandle() {
		try {
			const handleId = this.props.match.params.id;
			if (handleId === "new") return;
			
			const { data: handle } = await getHandle(handleId);
			const _id = handle[0]._id;
			this.setState({ data: this.mapToViewModel(handle[0]), _id });
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 404)
				this.props.history.replace("/not-found");
		}
	}

	async populateHistory() {
		try{
			const contentId = this.state._id;
			if (this.props.match.params.id === "new") return;

			const { data: history } = await getHistory(contentId);
			const dataHistory = await Promise.all(history.map(async (element) => {
				const objectArray = Object.values(element);
				
				try {
					const { data: userId } = await getUser(objectArray[1]);
					if (userId) {
						objectArray[1] = userId.email;
					}
				} catch (error) {
					console.log(error);
				}

				return objectArray;
			}));

			this.setState({ history: dataHistory });
		} catch (ex) {
			if (ex.response)
				console.log(ex);
		}
	}

	async componentDidMount() {
		await this.populateHandle();
		await this.populateHistory();
	}

	handleAutofill = async () => {
		try {
			let handleFilter = this.props.match.params.id;
			if (handleFilter === "new") {
				handleFilter = (this.state.data.handleId) ? this.state.data.handleId : this.state.data.name;
				if (!handleFilter) {
					return;
				}
			}
			
			const { data: handle } = await searchHandle(handleFilter);
			const handleData = this.mapToViewModel(handle);
			handleData._id = this.state.data._id;
			this.setState({ data: handleData });
		} catch (ex) {
			if (ex.response && ex.response.status === 404) {
				toast.error(ex.response.data);
			} else {
				console.log(ex.response);
			}
		}
	}

	mapToViewModel(handle) {
		return {
			_id: handle._id,
			name: handle.name,
			handleId: handle.handleId,
			fullName: handle.fullName,
			profileImage: handle.profileImage,
		};
	}

	doSubmit = async () => {
		const { data } = { ...this.state };
		let action = 'EDIT';
		
		if (this.props.match.params.id === "new") {
			action = 'NEW';
		}

		const { data: saveData } = await saveHandle(data);
		const contentId = saveData._id;
		const user = auth.getCurrentUser();
		
		const history = {
			userId: user._id,
			contentId,
			action,
			payload: JSON.stringify(data),
			contentType: 'Handle',
		}

		await saveHistory(history);

		this.props.history.push("/handles");
	};

	render() {	  
		return (
			<Grid container spacing={2} style={{marginTop: 20}}>
				<Grid item xs={12}>
					<Card style={{marginBottom: 20}}>
						<CardContent>
							<div className="title-container">
								<h1>Handle Form</h1>
							</div>
							<Alert severity="info" style={{marginBottom:20}}>
								You can autofill the Handle's information providing only the Handle's ID or the name: &nbsp;
								{(this.state.data.handleId || this.state.data.name)? <Button variant="contained" color="primary" style={{marginLeft:0}} endIcon={<AddIcon/>} onClick={this.handleAutofill}>Autofill</Button> : ""}
							</Alert>

							<Button variant="contained" style={{marginBottom:20}} startIcon={<KeyboardBackspaceIcon/>} component={Link} to="/handles">
								Back to Handles
							</Button>

							<form onSubmit={this.handleSubmit}>
								{this.renderInput("name", "Name")}
								{this.renderInput("handleId", "Handle ID")}
								{this.renderInput("fullName", "Full Name")}
								{this.renderInput("profileImage", "Profile Image")}
								{this.renderButton("Save")}
								{this.renderButton("Cancel", "link", "/handles")}
								
								<Button variant="contained" style={{marginTop:0}} startIcon={<KeyboardBackspaceIcon/>} component={Link} to="/handles">
									Back to Handles
								</Button>
							</form>
						</CardContent>
					</Card>

					{this.props.match.params.id !== "new" && this.state.history.length !== 0 &&
						<div className="history-content">
							<History data={this.state.history}/>
						</div>
	  				}
				</Grid>
			</Grid>
		);
  	}
}

export default HandleForm;
