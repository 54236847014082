import React from "react";
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import InputLabel from '@material-ui/core/InputLabel';

const SearchBox = ({ value, onChange }) => {
  	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel htmlFor="search-filter">Search...</InputLabel>
			<OutlinedInput size="small" label="Search..." margin="dense" value={value} onChange={e => onChange(e.currentTarget.value)} id="search-filter" startAdornment={
				<InputAdornment position="start">
					<FindInPageIcon />
				</InputAdornment>
			}/>
		</FormControl>
  	);
};

export default SearchBox;
